<template>
  <div>
    <div  class="pdf-mode " v-if="mode==='pdf'">
      <div class="radar-wrapper">
        <div class="summary-evaluate">
          <div class="name">综合评分</div>
          <div class="rate">
            <el-rate
              :value="data.score/20"
              :max="max"
              disabled
              text-color="#FF8023"
              :colors="['#FF8023','#FF8023','#FF8023']"
            >
            </el-rate>
            <div class="rate-score">{{ data.score }}分</div>
          </div>
        </div>

        <RadarChart :data="radarData" ></RadarChart>
      </div>
      <div class="table-wrapper">
        <BigPowerTable :data="data.data" class="table"></BigPowerTable>
      </div>

    </div>
    <div class="wrapper" v-else>
      <div class="radar-wrapper">
        <div class="summary-evaluate">
          <div class="name">综合评分</div>
          <div class="rate">
            <el-rate
              :value="data.score/20"
              :max="max"
              disabled
              text-color="#FF8023"
              :colors="['#FF8023','#FF8023','#FF8023']"
            >
            </el-rate>
            <div class="rate-score">{{ data.score }}分</div>
          </div>
        </div>

        <RadarChart :data="radarData" ></RadarChart>
      </div>
      <div class="table-wrapper">
        <BigPowerTable :data="data.data" class="table"></BigPowerTable>
      </div>

    </div>
  </div>

</template>

<script>
import RadarChart from "@/pages/Result/components/ResultHead/RadarChart";

export default {
  name: "FinanceAnalyze",
  components: {
    RadarChart
  },
  props: {
    data: Object,
    mode: String,
  },
  data() {
    return {
      currentAbilityIndex: null,
      max: 5,
      value: 3.7
    }
  },
  computed: {
    radarData() {
      return this.data.data.extendedData.map(v => {
        return {
          name: v.name,
          value: v.score
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  display flex;
  margin-top 20px

  >>> .el-rate__icon
    font-size 22px
  .radar-wrapper
    display flex
    flex-direction column
    width 480px
  .summary-evaluate
    display flex
    align-items center

    .name
      //margin-right 10px;
      margin: -1px 10px 0 0;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2129;

    .rate
      display flex
      align-items center
      position relative
      top -2px

      .rate-score
        color:#FFB100;
        font-size: 24px;
        font-family: DIN Alternate-Bold, DIN Alternate;
        font-weight: bold;
        margin:0px 10px;
.table-wrapper
  flex-grow 1
.table
  >>>.bigPowerTable
    max-height 552px
.wrapper >>>.el-rate__icon {
  font-size: 28px;
}
>>>.el-rate {
  height: 28px;
}
//表格样式
.table {
  border-bottom: 1px solid #e5e6eb;
  >>>table.table th {
    background: #F1F6FE;
    padding: 13px 12px;
    font-weight: bold;
  }
  >>>table.table td{
    padding: 13px 12px;
    font-size:14px;
    color: #1D2129;
    font-weight: 500;
  }
  /* 滚动条thumb的背景色 */
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background: #8D8F93;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
}
>>> .el-radio-button {
  margin-right 16px

  .el-radio-button__inner {
    border-radius: 2px;
    padding 6px 16px
    border 0 none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    box-shadow none;
  }
}

>>> .el-radio-button.is-active {
  .el-radio-button__inner {
    background: rgba(22, 93, 255, 0.1);
    color: #165DFF;
    font-weight bold
  }
}
>>>.el-radio-button.is-active .el-radio-button__inner{
  background: #F1F6FE;
  color:#2663F6;
  padding:8px 16px;
  border-radius: 4px;
}
>>>.el-radio-button .el-radio-button__inner{
  background: #F6F6F6;
  border-radius: 4px;
  padding:8px 16px;
  color:#1D2129
}
.radio{
  margin-bottom:16px
}
>>>.el-radio-button .el-radio-button__inner:hover{
  background:#F1F6FE
  }
  .table {
    //width 100%
    border-collapse: collapse;
    border-spacing: 0;
    //margin-top:20px


    td, th {
      text-align: justify;
      font-size: 14px;
      padding: 8px 6px;
      border: 1px solid rgba(238, 238, 238, 1);
      color: rgba(0, 0, 0, 0.6);
      vertical-align: top;
      min-width: auto;

      .clickable {
        color: #2F54EB;
        cursor: pointer;
      }
    }

    th {
      font-weight: 600;
      background: rgba(240, 245, 255, 1);
    }

    tr:nth-child(even) {
      td {
        //background: rgba(245, 245, 245, 1);
      }
    }

    .link {
      color: rgba(47, 84, 235, 1);
    }
  }
  .table th{
    padding:13px 12px;
    font-size: 14px;
    font-weight: bold;
    color: #1D2129;
  }
  .table td{
    padding:13px 12px;
    font-size: 14px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #1D2129;
  }

  table td span{
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;  /* 内容自动换行 */
    -webkit-box-orient: vertical; /* 垂直排列 */
    display: -webkit-box;
    -webkit-line-clamp:2;
  }
  /* 滚动条thumb的背景色 */
  ::-webkit-scrollbar-thumb {
    width: 8px;
    //height: 204px;
    background: #8D8F93;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
#bodyItem10{
  margin-top: 0
}
table {
  display: inline-block;
  height: 500px;
  overflow: auto;
  position: relative;
}
thead {
  background-color: #fff;
  position: sticky;
  top: 0;
}
</style>

<template>
  <div class="pdf-mode" v-if="mode==='pdf'">
    <Line2Chart class="column2Chart" :chartData="data.jsonData"
                v-if="data&&data.jsonData" :data-zoom="false"></Line2Chart>
    <div class="no-data" style="margin-top: 25px" v-else>暂无数据</div>
    <!--    <LineChart :data="chart" v-if="chart.imageType=='brokenLine'" width="849px"-->
    <!--               height="435px"></LineChart>-->
  </div>
  <div v-else>
    <Line2Chart v-loading="loading" class="column2Chart" :chartData="data.jsonData"
                v-if="data&&data.jsonData"></Line2Chart>
    
    
    <!--    <LineChart :data="chart" v-if="chart.imageType=='brokenLine'" width="849px"-->
    <!--               height="435px"></LineChart>-->
  </div>
</template>

<script>
import {getGXAmountCompare} from "@/api/threeYears";
import LineChart from "@/pages/Result/components/chats/LineChart";
import Line2Chart
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/Line2Chart";

export default {
  components: {
    Line2Chart
  },
  name: "PurchaseAndSaleMoney",
  props: {
    taxNo: String,
    zq: [String, Number],
    mode: String
  },
  async mounted() {
    this.loading=true

    await this.loadGXAmountCompare();
  },
  data() {
    return {
      loading:null,
      data: null,
    }
  },
  methods: {
    async loadGXAmountCompare() {
      const res = await getGXAmountCompare({
        taxNo: this.taxNo,
        zq: this.zq,
        programId: this.$route.params.uuid
      })
      this.data = res.data
    this.loading=false

    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"
.column2Chart
  width: 100%;
  height: 400px;
  margin-top 16px

.pdf-mode
  height 400px
</style>

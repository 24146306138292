<template>
  <div>
    <div v-if="mode==='pdf'" class="pdf-mode">
      <div class="pdf-main grp">
        <div>
          <UpDownCustomerNetWork mode="pdf"
                                 :orderNumber="node.orderNumber+''"></UpDownCustomerNetWork>
        </div>
        <div class="grp-title qiangzhi">
          {{ node.orderNumber + '.3' }} 上下游风险企业
        </div>
        <UpDownCustomerDistribution mode="pdf"
                                    :orderNumber="node.orderNumber + '.3'"></UpDownCustomerDistribution>
      </div>

    </div>
    <div class="main section" v-else>
      <div>
        <div class="section-menu-bar">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-bar"
            mode="horizontal"
            @select="handleTopMenuSelect"
          >
            <el-menu-item index="1">上下游客户网络</el-menu-item>
            <el-menu-item index="2">上下游客户分布</el-menu-item>
          </el-menu>
        </div>
        <div v-if="activeIndex==1">
          <UpDownCustomerNetWork></UpDownCustomerNetWork>
        </div>
        <div v-if="activeIndex==2">
          <UpDownCustomerDistribution></UpDownCustomerDistribution>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import UpDownCustomerNetWork
  from "@/pages/Result/ThreeYearResult/financialAnalysis/UpDownCustomerNetWork";
import UpDownCustomerDistribution
  from "@/pages/Result/ThreeYearResult/financialAnalysis/UpDownCustomerDistribution";


export default {
  name: "UpDownCustomer",
  components: {
    UpDownCustomerNetWork,
    UpDownCustomerDistribution
  },
  props: {
    mode: [String],
    node: [Object]
  },
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  }
}
</script>

<style lang="stylus" scoped>
//@import "~@/pages/pdfFrontGenerator/pdf.styl"
.section{
  width: 1100px;
  background: #FFFFFF;
  padding:20px;
  border-radius: 8px 8px 8px 8px;
  box-sizing: border-box
}
>>> .section-menu-bar .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 4px solid #165dff;
  border-radius: 2px 2px 2px 2px;
  color:#2663F6;
}
>>> .section-menu-bar .el-menu--horizontal .el-menu-item {
  padding: 0px 0px;
  font-size: 18px;
  color: #1D2129;
  margin: 0px;
  margin-right: 40px;
  height: 42px;
  line-height: 25px;
  font-weight: bold;
}

</style>

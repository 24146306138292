<template>
  <div v-if="companyInfo">
    <div class="wrapper pdf-mode " v-if="mode==='pdf'">
      <div class="grp-title">
        {{ orderNumber }} 采购品类
      </div>
      <!--      <div class="section-menu-bar">-->
      <!--        <el-menu-->
      <!--          :default-active="activeIndex"-->
      <!--          class="el-menu-bar"-->
      <!--          mode="horizontal"-->
      <!--          @select="handleTopMenuSelect"-->
      <!--        >-->
      <!--          <el-menu-item index="2">采购品类</el-menu-item>-->
      <!--          <el-menu-item index="1">销售品类</el-menu-item>-->
      <!--        </el-menu>-->
      <!--      </div>-->
      <div>
        <PurchaseAndSaleTabItem :zq="year+'004'" :taxNo="taxNo"
                                :companyType="2" :mode="mode"
                                :orderNumber="orderNumber+'.'+(index+1)"
                                v-for="(year,index) in yearsOption"
                                :key="year"></PurchaseAndSaleTabItem>
      </div>

      <div class="grp-title">
        {{ increaseOrder(orderNumber) }} 销售品类
      </div>

      <div>
        <PurchaseAndSaleTabItem :zq="year+'004'" :taxNo="taxNo"
                                :companyType="1" :mode="mode"
                                :orderNumber="increaseOrder(orderNumber)+'.'+(index+1)"
                                v-for="(year,index) in yearsOption"
                                :key="year"></PurchaseAndSaleTabItem>
      </div>
    </div>
    <div class="wrapper" v-else>
      <div class="section-menu-bar">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-bar"
          mode="horizontal"
          @select="handleTopMenuSelect"
        >
          <el-menu-item index="2">采购品类</el-menu-item>
          <el-menu-item index="1">销售品类</el-menu-item>
        </el-menu>
      </div>
      <div>
          <PurchaseAndSaleTabItem :zq="companyInfo.zq" :taxNo="taxNo"
                                :companyType="activeIndex"></PurchaseAndSaleTabItem>
        
      </div>
    </div>
  </div>

</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleTabItem
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSaleTabItem";
import {getCompanyInfo} from "@/api/threeYears";

export default {
  name: "PurchaseAndSaleTab",
  components: {
    PurchaseAndSaleTabItem,

  },
  props: {
    taxNo: String,
    mode: String,
    orderNumber: String,
  },
  data() {
    return {
      activeIndex: "2",
      companyInfo: null
    };
  },
  async mounted() {
    await this.loadCompanyInfo()
  },
  computed: {
    /**
     * 可选的年份
     * @returns {(number|number)[]}
     */
    yearsOption() {
      const zq = parseInt(this.companyInfo.zq.replace("004"))
      const ret = [zq, zq - 1, zq - 2]
      return ret
    }
  },
  methods: {

    increaseOrder(orderNumber) {
      const split = orderNumber.split('.');
      split[split.length - 1] = parseInt(split[split.length - 1]) + 1
      return split.join('.')
    },
    async loadCompanyInfo() {
      const res = await getCompanyInfo({programId: this.$route.params.uuid})
      this.companyInfo = res.data
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },

  }
}
</script>

<style lang="stylus" scoped>
//@import "~@/pages/pdfFrontGenerator/pdf.styl"
.wrapper
  margin-top 40px
  position relative

  .section-menu-bar
    border-bottom 1px solid #eee;


</style>

<template>
  <div>
    <div v-if="mode==='pdf'" class="pdf-mode">
      <div class="grp-title">
        {{ orderNumber + '.1' }} 上下游客户网络
      </div>
      <div class="desc">
        {{ $store.state.PdfResult.timeRange[0] }}至{{
          $store.state.PdfResult.timeRange[1]
        }}，累计交易金额排名前20的上下游客户，如果风险企业不超过20家，以实际数量为准。
      </div>
      <NetWorkChartWindow :mode="mode" :orderNumber="orderNumber"></NetWorkChartWindow>
      <div class="grp-title">
        {{ orderNumber + '.2' }} 上下游风险企业
      </div>
      <CompanyRiskLookup :mode="mode" :companyType="2" :orderNumber="orderNumber+'.2.1'"
                         title="上游风险企业"
                         :desc="`${ $store.state.PdfResult.timeRange[0] }至${$store.state.PdfResult.timeRange[1]}，被系统定义为有风险的上游客户，此处按交易金额展示前20家风险企业，如果风险企业不超过20家，以实际数量为准。`"></CompanyRiskLookup>
      <CompanyRiskLookup :mode="mode" :companyType="1" :orderNumber="orderNumber+'.2.2'"
                         title="下游风险企业"
                         :desc="`${ $store.state.PdfResult.timeRange[0] }至${$store.state.PdfResult.timeRange[1]}，被系统定义为有风险的下游客户，此处按交易金额展示前20家风险企业，如果风险企业不超过20家，以实际数量为准。`"></CompanyRiskLookup>
      <div class="title3">
        {{ orderNumber + '.2.3' }} 风险类型说明
      </div>
      <div class="simple-text-grp">
        <div class="text gray">系统会对上下游客户进行以下类型的风险检测，可对照上文查看。</div>
        <div class="text">司法风险<br>严重违法：机构存在违反国家现行法律规定的行为，且情节较严重；行政处罚：机构存在违反国家现行法律规定的行为，并被行政处罚；失信人：机构有履行能力而拒不履行生效法律文书确定的义务；被执行人：机构被列为被执行人；限制消费令：机构因拒不执行法院生效法律文书，被采取"限制高消费"的严厉措施；法律诉讼：机构存在法律诉讼。</div>
        <div class="text">税务风险<br>税务违法：机构存在包括但不限于涉嫌愉税，逃避追缴欠税，骗税，虚开、伪造、变造发票等税务违法行为；欠税：机构有欠税情况，且被相关部分公开发布过欠税公告；纳税信用等级低：机构最新的纳税信用等级为M、C、D。</div>
        <div class="text">经营风险<br>经营状态异常：机构的经营状态为吊销、注销、停业、清算等异常状态；经营异常：机构存在未按规定公示年报或公示的地址无法联系等情况，被相关部门列入异常名录；社保人数异常：指该企业在工商年报中披露的社保人数偏低，可能存在工商年报数据更新滞后或者企业误报的情况，请注意核查；成立不满6个月：成立不足6个月，可能存在发票风险，值得确认关注；个人独资企业：机构为个人独资企业，值得关注；个体工商：对方为个人工商户，值得关注。</div>
      </div>

    </div>
    <div v-else>
      <NetWorkChartWindow :mode="mode"></NetWorkChartWindow>
      <CompanyRiskLookup></CompanyRiskLookup>
    </div>
  </div>

</template>

<script>
import CompanyRiskLookup
  from "@/pages/Result/ThreeYearResult/financialAnalysis/companyRisk/CompanyRiskLookup";
import {getCompanyAreaList, getCompanyRiskImage} from "@/api/threeYears";
import NetWorkChartWindow
  from "@/pages/Result/ThreeYearResult/financialAnalysis/networkChart/NetWorkChartWindow";

export default {
  name: "UpDownCustomerNetWork",
  components: {
    CompanyRiskLookup,
    NetWorkChartWindow
  },
  props: {
    mode: String,
    orderNumber: String
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {}
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"
</style>

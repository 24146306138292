<template>
  <div>
    <div v-if="mode=='pdf'">
      <div class="grp-title mb">{{orderNumber}}{{ data.name }}</div>
      <BigPowerTable :data="data.data"></BigPowerTable>
    </div>
    <div v-else>
      <div class="name">{{ data.name }}</div>
      <BigPowerTable :data="data.data"></BigPowerTable>
    </div>
  </div>

</template>

<script>
export default {
  name: "TaxCreditLevel",
  props: {
    data: [Object],
    mode:String,
    orderNumber:String
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"
.name
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 20px;
  margin-top 24px;
  margin-bottom 16px
</style>

<template>
  <div v-if="loaded">
    <div class="pdf-mode " v-if="mode === 'pdf'">
      <div class="pdf-main grp">
        <div class="grp-title">
          {{ node.orderNumber + '.1' }} 购销金额波动
        </div>
        <div>
          <PurchaseAndSaleMoney :taxNo="companyInfo.taxNo" :zq="companyInfo.zq" :mode="mode"></PurchaseAndSaleMoney>
        </div>
        <div class="grp-title">
          {{ node.orderNumber + '.2' }} 购销品类对比
        </div>
        <div>
          <PurchaseAndSaleCategoryCompare :mode="mode" :taxNo="companyInfo.taxNo" :zq="companyInfo.zq"
            :orderNumber="node.orderNumber + '.3'"></PurchaseAndSaleCategoryCompare>

        </div>
      </div>
    </div>
    <div class="main section" v-else v-load="!loaded" style="min-height: 200px">
      <div class="section-menu-bar">
        <el-menu :default-active="activeIndex" class="el-menu-bar" mode="horizontal" @select="handleTopMenuSelect">
          <el-menu-item index="1">购销金额波动</el-menu-item>
          <el-menu-item index="2">购销品类对比</el-menu-item>
        </el-menu>
      </div>
      <div v-if="activeIndex=='1'">
        <keep-alive>
          <PurchaseAndSaleMoney :taxNo="companyInfo.taxNo" :zq="companyInfo.zq"></PurchaseAndSaleMoney>

        </keep-alive>
      </div>
      <div v-if="activeIndex=='2'">
        <keep-alive>
          <PurchaseAndSaleCategoryCompare :taxNo="companyInfo.taxNo"
                                        :zq="companyInfo.zq"></PurchaseAndSaleCategoryCompare>

        </keep-alive>
        
      </div>
    </div>
  </div>


</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleMoney
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSaleMoney";
import { getCompanyInfo } from "@/api/threeYears";
import PurchaseAndSaleCategoryCompare
  from "@/pages/Result/ThreeYearResult/financialAnalysis/purchaseAndSale/PurchaseAndSaleCategoryCompare";

export default {
  components: {
    PurchaseAndSaleMoney,
    PurchaseAndSaleCategoryCompare
  },
  name: "PurchaseAndSale",
  props: {
    mode: String,
    node: Object
  },
  data() {
    return {
      activeIndex: "1",
      loaded: false,
      companyInfo: {}
    };
  },
  async mounted() {
    this.loaded = false
    await this.loadCompanyInfo();
    this.loaded = true

  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({ programId: this.$route.params.uuid })
      this.companyInfo = res.data
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  }
}
</script>

<style lang="stylus" scoped>
//@import "~@/pages/pdfFrontGenerator/pdf.styl"
>>> .section-menu-bar .el-menu--horizontal .el-menu-item.is-active {
  border-bottom: 4px solid #165dff;
  border-radius: 2px 2px 2px 2px;
  color:#2663F6;
}
>>> .section-menu-bar .el-menu--horizontal .el-menu-item {
  padding: 0px 0px;
  font-size: 18px;
  color: #1D2129;
  margin: 0px;
  margin-right: 40px;
  height: 42px;
  line-height: 25px;
  font-weight: bold;
}
.section{
  width: 1100px;
  background: #FFFFFF;
  padding:20px;
  border-radius: 8px 8px 8px 8px;
  box-sizing: border-box
}
</style>

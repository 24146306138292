<template>
  <div>
    <div class="section-title">
      <div class="titleIcon"></div>
      <div class="title">
        风险企业查询
        <!-- <el-tooltip
          class="item"
          effect="dark"
          content="系统默认提供“单笔交易金额5万及以上企业”的风险查询，如需完整数据可拨打客服热线：0571-85055930"
          placement="right-start"
        >
          <i class="el-icon-warning-outline el-icon-warning-outline-dark"></i>
        </el-tooltip> -->
        <el-tooltip class="item" effect="dark" placement="top" popper-class="box-item">
          <div slot="content">
            可查询的风险企业需满足如下条件：<br />
            1、自然年度内交易的价税合计金额排名前50（含)的上游企业和下游企业；<br />
            2、有风险的企业
          </div>
          <i class="iconfont iconicon_tips"></i>
        </el-tooltip>
      </div>
    </div>
    <div class="toolbar">
      <el-form ref="form" :model="filter" class="section-toolbar">
        <div class="row" style="margin-bottom: 20px">
          <el-form-item>
            <span>客户类型:</span>
            <el-select
              @change="handleCompanyTypeChange"
              v-model="filter.companyType"
              size="mini"
              placeholder="请选择"
              style="width: 240px"
            >
              <el-option
                v-for="item in customerTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-right: 12px">
            <span>公司名称:</span>
            <el-select
              @input="handleBtnCompanyNameChange"
              style="width: 240px"
              v-model="filter.companyName"
              filterable
              clearable
              size="mini"
              remote
              placeholder="请输入公司名称"
              :remote-method="getRemoteCompanyList"
              :loading="companyLoading"
            >
              <el-option
                v-for="item in companyNameOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <!--            <el-select v-model="filter.companyName" filterable placeholder="请选择">-->
            <!--              <el-option-->
            <!--                v-for="item in options"-->
            <!--                :key="item.value"-->
            <!--                :label="item.label"-->
            <!--                :value="item.value">-->
            <!--              </el-option>-->
            <!--            </el-select>-->
            <!--            <el-input v-model.number="filter.companyName" size="mini"-->
            <!--                      style="width: 240px;"></el-input>-->
          </el-form-item>
          <el-form-item>
            <span>时间:</span>
            <el-date-picker
              v-model="filter.time"
              type="daterange"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              size="mini"
              style="width: 240px"
              value-format="yyyy-MM-dd"
              @change="clearCompanyNameAndLoadCompanyRiskList"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="row" style="margin-bottom: 20px">
          <!-- <el-form-item label="金额排名：" style="margin-right: 12px">
            <BigPowerInput
              type="number"
              v-model="filter.startMoneyRanking"
              :min="1"
              :max="10000000000"
              size="mini"
              style="width: 60px"
              @change="handleMoneyRankingChange"
            ></BigPowerInput>
          </el-form-item> -->
          <!-- <el-form-item label="至">
            <BigPowerInput
              type="number"
              v-model="filter.endMoneyRanking"
              size="mini"
              :min="1"
              :max="10000000000"
              style="width: 60px"
              @change="handleMoneyRankingChange"
            ></BigPowerInput>
          </el-form-item> -->
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="表示不含税金额"
            placement="top"
            style="position: relative; top: 7px"
          >
            <i class="el-icon-warning tip"></i>
          </el-tooltip> -->
          <el-form-item style="margin-right: 57px">
            <span>
              金额（万元）
              <el-tooltip
                class="item"
                effect="dark"
                content="表示不含税金额"
                placement="top"
                popper-class="box-item"
              >
                <i class="iconfont iconicon_tips"></i>
              </el-tooltip>
              :
            </span>
            <BigPowerInput
              type="number"
              v-model="filter.startMoney"
              size="mini"
              style="width: 78px"
              @input="handleMoneyChange"
            ></BigPowerInput>
            <span> &nbsp; 至 </span>
            <BigPowerInput
              type="number"
              v-model="filter.endMoney"
              size="mini"
              style="width: 78px"
              @input="handleMoneyChange"
            ></BigPowerInput>
          </el-form-item>
          <!-- <el-form-item>
            <span>至</span>
            <BigPowerInput
              type="number"
              v-model="filter.endMoney"
              size="mini"
              style="width: 78px"
              @change="handleMoneyChange"
            ></BigPowerInput>
          </el-form-item> -->
          <el-form-item class="item" prop="companyArea">
            <span>地区:</span>

            <!-- <Cascader
              :options="areaOptions"
              v-model="filter.companyArea"
              :placeholder="diquPlaceHolder"
              clearable
              filterable
              class="input"
              :props="{ checkStrictly: true }"
              size="mini"
              @change="clearCompanyNameAndLoadCompanyRiskList"
              style="width: 240px"
            ></Cascader> -->
          </el-form-item>
        </div>
        <div class="checkBox-row-wrapper">
          <div class="row">
            <el-form-item class="item" prop="judicialRiskList">
              <div class="itemTooltip">
                司法风险
                <el-tooltip class="item" effect="dark" placement="top" popper-class="box-item">
                  <div slot="content">
                    严重违法：机构存在违反国家现行法律规定的行为，且情节较严重；<br />
                    行政处罚：机构存在违反国家现行法律规定的行为，并被行政处罚；<br />
                    失信人：机构有履行能力而拒不履行生效法律文书确定的义务；<br />
                    被执行人：机构被列为被执行人；<br />
                    限制消费令：机构因拒不执行法院生效法律文书，被采取"限制高消费"的严厉措施；<br />
                    法律诉讼：机构存在法律诉讼。
                  </div>
                  <i class="iconfont iconicon_tips"></i>
                </el-tooltip>
                :
              </div>
              <el-checkbox-group
                v-model="filter.judicialRiskList"
                @change="handleJudicialRiskListClick"
              >
                <el-checkbox label="不限"></el-checkbox>
                <el-checkbox label="法律诉讼"></el-checkbox>
                <el-checkbox label="被执行人"></el-checkbox>
                <el-checkbox label="失信人"></el-checkbox>
                <el-checkbox label="严重违法"></el-checkbox>
                <el-checkbox label="行政处罚"></el-checkbox>
                <el-checkbox label="限制消费令"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item class="item" prop="taxationRiskList">
              <div class="itemTooltip">
                税务风险
                <el-tooltip class="item" effect="dark" placement="top" popper-class="box-item">
                  <div slot="content">
                    税务违法：机构存在包括但不限于涉嫌愉税，逃避追缴欠税，骗税，虚开、伪造、变造发票等税务违法行为；<br />
                    欠税：机构有欠税情况，且被相关部分公开发布过欠税公告；<br />
                    纳税信用等级低：机构最新的纳税信用等级为M、C、D;<br />
                    "税收违法"中包含"非正常户"企业。
                  </div>
                  <i class="iconfont iconicon_tips"></i>
                </el-tooltip>
                :
              </div>
              <el-checkbox-group
                v-model="filter.taxationRiskList"
                @change="handleTaxationRiskListClick"
              >
                <el-checkbox label="不限"></el-checkbox>
                <el-checkbox label="税收违法">税收违法</el-checkbox>
                <el-checkbox label="欠税"></el-checkbox>
                <el-checkbox label="纳税信用等级低"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div class="row">
            <el-form-item class="item" prop="managementRiskList">
              <div class="itemTooltip">
                经营风险
                <el-tooltip class="item" effect="dark" placement="top" popper-class="box-item">
                  <div slot="content">
                    经营状态异常：机构的经营状态为吊销、注销、停业、清算等异常状态；<br />
                    经营异常：机构存在未按规定公示年报或公示的地址无法联系等情况，被相关部门列入异常名录；<br />
                    社保人数异常：机构在工商年报中披露的社保人数偏低，可能存在工商年报数据更新滞后或者企业误报的情况，需注意核查；<br />
                    成立不满6个月：成立不足6个月，可能存在发票风险，值得确认关注；<br />
                    个人独资企业：机构为个人独资企业，值得关注；<br />
                    个体工商：对方为个人工商户，值得关注。
                  </div>
                  <i class="iconfont iconicon_tips"></i>
                </el-tooltip>
                :
              </div>
              <el-checkbox-group
                v-model="filter.managementRiskList"
                @change="handleManagementRiskListClick"
              >
                <el-checkbox label="不限"></el-checkbox>
                <el-checkbox label="经营状态异常"></el-checkbox>
                <el-checkbox label="经营异常"></el-checkbox>
                <el-checkbox label="社保人数异常"></el-checkbox>
                <el-checkbox label="成立不满6个月"></el-checkbox>
                <el-checkbox label="个人独资企业"></el-checkbox>
                <el-checkbox label="个体工商户"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="table-wrapper">
      <div class="tip">
        符合条件的企业共 <span style="color: #2663f6">{{ number }}</span> 家
      </div>
      <BigPowerTable :data="processedTable" v-if="processedTable" class="table"></BigPowerTable>
    </div>
    <CompanyRiskDialog ref="CompanyRiskDialog"></CompanyRiskDialog>
  </div>
</template>

<script>
import { cloneDeep, debounce } from "lodash";
import { areaList } from "@/api/api";
// import Cascader from "@/components/Cascader";
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";
import { getCompanyRiskList, getRiskCompanyList } from "@/api/threeYears";
import CompanyRiskDialog from "@/pages/Result/ThreeYearResult/financialAnalysis/companyRisk/CompanyRiskDialog";

export default {
  components: {
    // Cascader,
    BigPowerTable,
    CompanyRiskDialog,
  },
  name: "CompanyRiskLookup",
  data() {
    return {
      companyLoading: false,
      areaTree: [],
      customerTypeOptions: [
        {
          label: "上游企业",
          value: 2,
        },
        {
          label: "下游企业",
          value: 1,
        },
      ],
      filterOrigin: {},
      filter: {
        companyType: 2,
        companyName: "",
        time: [],
        startMoneyRanking: "",
        endMoneyRanking: "",
        startMoney: "",
        endMoney: "",
        companyArea: [],
        showCompanyRisk: false,
        judicialRiskList: ["不限"],
        taxationRiskList: ["不限"],
        managementRiskList: ["不限"],
      },
      judicialRiskListPrev: ["不限"],
      taxationRiskListPrev: ["不限"],
      managementRiskListPrev: ["不限"],
      companyNameOptions: [],
      diquPlaceHolder: "请选择",
      table: null,
    };
  },
  mounted() {
    this.filterOrigin = cloneDeep(this.filter);

    this.getAreaList();
    this.loadCompanyRiskList();
  },
  computed: {
    number() {
      if (this.table == null) {
        return 0;
      } else {
        return this.table.body.length;
      }
    },
    processedTable() {
      if (this.table == null) {
        return null;
      }

      const header = this.table.header.map((row) => {
        const widths = ["240px", "136px", "120px", "120px", "160px", "320px", "64px"];
        return row.map((cell, index) => {
          if (widths[index]) {
            cell.width = widths[index];
          }
          return cell;
        });
      });

      const body = this.table.body.map((row) => {
        return row.map((cell) => {
          if (cell.detail) {
            cell.click = (cell) => {
              this.openCompanyRiskDetailDialog(cell.detail);
            };
          }
          return cell;
        });
      });
      return {
        header: header,
        body: body,
      };
    },
    areaOptions: function () {
      if (!this.areaTree) {
        return [];
      }

      const ret = cloneDeep(this.areaTree);

      function walk(data, level) {
        data.value = data.code;
        data.label = data.name;
        if (level < 1 && data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child, level + 1);
          }
        }
        delete data.childNode;
      }

      for (const areaTree of ret) {
        walk(areaTree, 0);
      }

      return [...ret];

      // return [
      //   {
      //     value: -9999999,
      //     label: "地区",
      //     children: [...ret],
      //   },
      // ];
    },
  },
  methods: {
    handleCompanyTypeChange(val) {
      this.filter = cloneDeep(this.filterOrigin);
      this.judicialRiskListPrev = ["不限"];
      this.taxationRiskListPrev = ["不限"];
      this.managementRiskListPrev = ["不限"];
      this.filter.companyType = val;
      console.log(this.filter);
      this.loadCompanyRiskList();
    },
    clearCompanyNameAndLoadCompanyRiskList() {
      this.clearCompanyName();
      this.loadCompanyRiskList();
    },
    // handleMoneyRankingChange() {
    //   this.filter.startMoney = "";
    //   this.filter.endMoney = "";
    //   this.clearCompanyName();
    //   this.loadCompanyRiskList();
    // },
    handleMoneyChange() {
      this.filter.startMoneyRanking = "";
      this.filter.endMoneyRanking = "";
      this.clearCompanyName();
      this.loadCompanyRiskList();
    },
    clearCompanyName() {
      this.filter.companyName = "";
    },
    handleJudicialRiskListClick(val) {
      this.clearCompanyName();
      if (val.includes("不限") && !this.judicialRiskListPrev.includes("不限")) {
        this.filter.judicialRiskList = ["不限"];
      } else {
        const findIndex = this.filter.judicialRiskList.findIndex((v) => v == "不限");
        if (findIndex >= 0) {
          this.filter.judicialRiskList.splice(findIndex, 1);
        }
      }
      this.judicialRiskListPrev = cloneDeep(this.filter.judicialRiskList);
      this.loadCompanyRiskList();
    },
    handleTaxationRiskListClick(val) {
      this.clearCompanyName();
      if (val.includes("不限") && !this.taxationRiskListPrev.includes("不限")) {
        this.filter.taxationRiskList = ["不限"];
      } else {
        const findIndex = this.filter.taxationRiskList.findIndex((v) => v == "不限");
        if (findIndex >= 0) {
          this.filter.taxationRiskList.splice(findIndex, 1);
        }
      }
      this.taxationRiskListPrev = cloneDeep(this.filter.taxationRiskList);
      this.loadCompanyRiskList();
    },
    handleManagementRiskListClick(val) {
      this.clearCompanyName();
      if (val.includes("不限") && !this.managementRiskListPrev.includes("不限")) {
        this.filter.managementRiskList = ["不限"];
      } else {
        const findIndex = this.filter.managementRiskList.findIndex((v) => v == "不限");
        if (findIndex >= 0) {
          this.filter.managementRiskList.splice(findIndex, 1);
        }
      }
      this.managementRiskListPrev = cloneDeep(this.filter.managementRiskList);
      this.loadCompanyRiskList();
    },
    async getRemoteCompanyList(query) {
      if (query !== "") {
        this.loading = true;

        const res = await getRiskCompanyList({
          companyType: this.filter.companyType,
          programId: this.$route.params.uuid,
          companyName: query,
        });

        setTimeout(async () => {
          if (res.code == 200) {
            this.companyNameOptions = res.data.map((v) => {
              return {
                value: v,
                label: v,
              };
            });
            this.loading = false;
          }
        }, 200);
      } else {
        this.companyNameOptions = [];
      }
      //companyNameOptions
    },
    openCompanyRiskDetailDialog(taxNo) {
      this.$refs["CompanyRiskDialog"].open(taxNo);
    },
    loadList() {},
    // 获取地区
    getAreaList() {
      areaList().then((res) => {
        this.areaTree = res.data;
      });
    },
    handleBtnCompanyNameChange(val) {
      const companyType = this.filter.companyType;

      this.filter = cloneDeep(this.filterOrigin);
      this.filter.companyType = companyType;
      this.filter.companyName = val;
      this.loadCompanyRiskList();
    },
    /**
     * 得到表格数据
     * @returns {Promise<void>}
     */
    async loadCompanyRiskList() {
      let judicialRiskList = cloneDeep(this.filter.judicialRiskList);
      if (judicialRiskList.includes("不限")) {
        judicialRiskList = ["法律诉讼", "被执行人", "失信人", "严重违法", "行政处罚", "限制消费令"];
      }

      let taxationRiskList = cloneDeep(this.filter.taxationRiskList);
      if (taxationRiskList.includes("不限")) {
        taxationRiskList = ["税收违法", "欠税", "纳税信用等级低"];
      }

      let managementRiskList = cloneDeep(this.filter.managementRiskList);
      if (managementRiskList.includes("不限")) {
        managementRiskList = [
          "经营状态异常",
          "经营异常",
          "社保人数异常",
          "成立不满6个月",
          "个人独资企业",
          "个体工商户",
        ];
      }

      const params = {
        programId: this.$route.params.uuid,
        companyType: this.filter.companyType,
        companyName: this.filter.companyName,
        companyArea: this.filter.companyArea,
        endMoney: this.filter.endMoney,
        endMoneyRanking: this.filter.endMoneyRanking,
        judicialRiskList: judicialRiskList,
        managementRiskList: managementRiskList,
        startMoney: this.filter.startMoney,
        startMoneyRanking: this.filter.startMoneyRanking,
        taxationRiskList: taxationRiskList,
        desensitizationStatus: this.$route.query.desensitization,
      };

      if (this.filter.time && this.filter.time[0]) {
        params.startDate = this.filter.time[0];
        params.endDate = this.filter.time[1];
      }

      const res = await getCompanyRiskList(params);
      this.table = res.data;
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> .el-select .el-input__inner {
  //padding-right 0
}

.section-title
  height: 22px;
  color: #2663F6;
  line-height: 22px;
  display flex
  //justify-content space-between
  border-bottom 1px solid #F2F3F5;
  padding-bottom 18px
  margin-top: 40px

  .title
    font-size: 18px;
    font-weight: 600;
  .titleIcon
    width: 4px;
    height: 20px;
    background: #2663F6;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-right: 20px
.section-toolbar:last-child
  margin-bottom:0
.section-toolbar
  display flex
  flex-direction column
  justify-content flex-start
  align-items flex-start
  margin-top 20px
  margin-bottom 16px

  .checkBox-row-wrapper
    border-top 1px solid #F2F3F5
    padding-top 20px

  .row
    display flex
    width 100%
    margin-bottom 16px


    >>> .el-form-item
      display flex

      .el-form-item__label
        line-height 1.5
        display flex
        align-items center

      .el-form-item__content
        line-height 1.5

    .tip
      position relative
      top 2px

.table-wrapper
  .tip
    color: #4E5969;
    font-size: 14px;
    margin-bottom 10px


  >>> .table.table {
    border 0 none
  }

  >>> .bigPowerTable {
    max-height 552px
  }


.el-icon-warning-outline-dark {
  margin-left: 6px;
  cursor: pointer;
}
>>> .el-range-editor--mini.el-input__inner {
  height: 36px;
}
>>> .el-input--mini .el-input__inner {
  height: 36px;
}
//表格样式
  .table {
    >>> .bigPowerTable {
      max-height 552px
      overflow auto
      border-bottom: 1px solid rgba(29,33,41,0.1);

    }
    >>>table.table th {
      background: #F1F6FE;
      padding: 13px 12px;
      font-weight: bold;
    }
    >>>table.table td{
      padding: 13px 12px;
      font-size:14px;
      color: #1D2129;
      font-weight: 500;
    }
    /* 滚动条thumb的背景色 */
    ::-webkit-scrollbar-thumb {
      width: 8px;
      //height: 204px;
      background: #8D8F93;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
    }
  }
>>>.table-wrapper .table .table {
  border-top: 1px solid #e5e6eb;
  border-left: 1px solid #e5e6eb;
}
.section-toolbar{
  span{
    margin-right:10px;
    font-size: 14px;
    color:#606266;
  }
}
>>>.el-form-item__content {
  display: flex;
}
.itemTooltip{
  margin-right: 16px;
}
>>>.section-toolbar .row[data-v-f6f92518] .el-form-item .el-form-item__content{
  line-height:2.5
}
</style>
